$header-height: 50px;
$header-background: linear-gradient(#0000008a, 50%, #00000000);
$header-border: 1px solid transparent;
$sidebar-show: 500px;


%text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/** Converts scss style vars to :host style vars */
@mixin var($name, $var) {
  --#{$name}: var(--e-#{$name}, #{$var});
}


header {
  @include var(header-height, $header-height);
  @include var(header-background, $header-background);
  @include var(header-logo-color, #fff);
  top: 0;
  z-index: 1000;

  .container {
    height: var(--header-height);
    background: var(--header-background);
    border-bottom: var(--header-border);
    display: grid;
    width: 100vw;
    align-items: center;
    grid-template-columns: auto;

    .logo {
      @extend %text-overflow;
      margin-left: 25px;
      height: var(--header-height);

      @media (max-width: $sidebar-show) {
        margin-left: 0;
        text-align: center;
      }

      h1 {
        color: var(--header-logo-color);
        font-size: 24px;
        margin: 0;
        line-height: var(--header-height);
      }
    }
  }

  + .placeholder {
    display: none;
    height: var(--header-height);
    width: 100%;
  }

  &.fixed {
    position: fixed;

    + .placeholder {
      display: block;
    }
  }
}
